import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
// import { NgxAnalyticsGoogleAnalytics } from 'ngx-analytics/ga';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import 'hammerjs';

// NgxAnalyticsGoogleAnalytics.prototype.createGaSession(environment.googleAnalytics);

if (environment.production) {
	enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
	.catch(err => console.error(err));
